import router from '@/router'
// eslint-disable-next-line object-curly-newline
import { reactive, getCurrentInstance, watch, toRefs } from '@vue/composition-api'

// Notification
// import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export const isObject = obj => typeof obj === 'object' && obj !== null

export const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

const getRandomFromArray = array => array[Math.floor(Math.random() * array.length)]

// ? Light and Dark variant is not included
// prettier-ignore
export const getRandomBsVariant = () => getRandomFromArray(['primary', 'secondary', 'success', 'warning', 'danger', 'info'])

export const isDynamicRouteActive = route => {
  const { route: resolvedRoute } = router.resolve(route)
  return resolvedRoute.path === router.currentRoute.path
}

// Thanks: https://medium.com/better-programming/reactive-vue-routes-with-the-composition-api-18c1abd878d1
export const useRouter = () => {
  const vm = getCurrentInstance().proxy
  const state = reactive({
    route: vm.$route
  })

  watch(
    () => vm.$route,
    r => {
      state.route = r
    }
  )

  return { ...toRefs(state), router: vm.$router }
}

/**
 * This is just enhancement over Object.extend [Gives deep extend]
 * @param {target} a Object which contains values to be overridden
 * @param {source} b Object which contains values to override
 */
// export const objectExtend = (a, b) => {
//   // Don't touch 'null' or 'undefined' objects.
//   if (a == null || b == null) {
//     return a
//   }

//   Object.keys(b).forEach(key => {
//     if (Object.prototype.toString.call(b[key]) === '[object Object]') {
//       if (Object.prototype.toString.call(a[key]) !== '[object Object]') {
//         // eslint-disable-next-line no-param-reassign
//         a[key] = b[key]
//       } else {
//         // eslint-disable-next-line no-param-reassign
//         a[key] = objectExtend(a[key], b[key])
//       }
//     } else {
//       // eslint-disable-next-line no-param-reassign
//       a[key] = b[key]
//     }
//   })

//   return a
// }

export const formatAmount = value => {
  console.log(value)
  let newValue = value ? value.replace(/^0+|[^0-9.]/g, '') : '' // Replace non-numeric characters except decimal point and leading zeros
  const decimalCount = (newValue.match(/\./g) || []).length // Count decimal points
  if (decimalCount > 1) {
    // If there are multiple decimal points, remove all but the first one
    const lastIndex = newValue.lastIndexOf('.')
    newValue = newValue.slice(0, lastIndex) + newValue.slice(lastIndex + 1).replace(/\./g, '')
  }
  const parts = newValue.split('.')
  parts[0] = parts[0].replace(/(\d)(?=(?:\d{3})+(?:\.|$))|(\.\d\d?)\d*$/g, (m, s1, s2) => s2 || `${s1} `) // Add thousands separator
  if (parts[1]) {
    parts[1] = parts[1].slice(0, 2) // Limit decimal places to two
  }
  return parts.join('.')
}

export const roundUp = num => {
  const x = num ? Math.round(num * 1000) / 1000 : 0
  const parts = x.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  return parts.join('.')
}

export const roundUpCustom = (givnum, decimals = 2) => {
  if (givnum !== null) {
    const num = Number.isNaN(givnum) ? 0 : givnum
    const tem = 10 ** decimals
    const preval = (Math.round(num * tem + (decimals > 0 ? 1 : 0) * (Math.sign(num) * (10 / 100 ** decimals))) / tem).toFixed(decimals)
    const parts = preval.toString().split('.')
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    return parts.join('.')
  }
  return 0
}

export const toastError = (toast, errorMessage) => {
  // const toast = useToast()
  toast({
    component: ToastificationContent,
    props: {
      title: 'Error adding new purchase',
      icon: 'AlertTriangleIcon',
      variant: 'danger',
      text: errorMessage
    }
  })
}
